import React from "react"

import Header   from "../../components/Header/upcc"
import WallNews from "../../components/upcc/wall-news"
import Audiovisual from "../../components/upcc/audiovisual"
import Footer   from "../../components/share/footer"


export default () => {

  return (
    <>
      <Header/>

      <div class="upcc-container">

        <WallNews/>

        <Audiovisual/>

      </div>

      <Footer/>
    </>
  )
}
